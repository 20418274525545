import "./styles.scss"

import React from "react"

import Section from "components/Section"

const CookiesPolicy = ({ content }) => {
  return content ? (
    <Section className="cookies-content" top={false} bottom={false}>
      <div className="row justify-content-center">
        <div
          className="col-md-10"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </Section>
  ) : null
}

export default CookiesPolicy
